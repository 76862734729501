import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { CountryStateService } from 'src/app/shared/country-state.service';
import { UsersService } from 'src/app/core/users.service';
import { Profile } from 'src/app/shared/interface/profile.interface';
import { NgForm } from '@angular/forms';
// drag and drop
import { FileSystemFileEntry, FileSystemDirectoryEntry } from '../../shared/dom.types';
import { UploadEvent, UploadFile } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/shared/communication';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { MemberState } from 'src/app/shared/store/member-profile/m-profile.reducer';
import { MemberProfile } from 'src/app/shared/interface/m-profile.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnChanges {
  // @Input() user: Profile;
  @ViewChild('profileCloseBtn') profileCloseBtn: ElementRef;
  @ViewChild('closeMainModal') closeMainModal: ElementRef;
  memberSubscription = Subscription;
  cstates;
  fileToUpload: File = null;
  countryCode;
  countries;
  roles;
  memberData: MemberProfile;
  public files: UploadFile[] = [];
  constructor(  private states: CountryStateService,
                private userService: UsersService,
                private toastr: ToastrService,
                private communicationService: CommunicationService,
                private memberStore: Store<MemberState>,
    ) { }

  ngOnChanges() {
    // if (this.user) {
    // }
  }

  ngOnInit() {
    this.countries = this.states.getCountries();
    // this.communicationService.getMessage().subscribe(
    //   message => {
    //     // console.log(message);
    //     if (message) {
    //       // console.log(message);
    //       // this.assignData(message);
    //     }
    //   }
    // );

  this.memberStore
    .select('member')
    .subscribe( data => {
      if (data) {
        this.assignData(data);
      }
    });
  }

  assignData(data) {
    this.memberData = data;
    // this.memberData = JSON.parse(localStorage.getItem('member'));
    this.countries = this.states.getCountries();
    const countryData = this.states.getStates(this.memberData.country);
    this.cstates = countryData.states;
    this.countryCode =  this.memberData.country_code ?  this.memberData.country_code : countryData.country_code;
  }

  onChangeCountry(data) {
    const countryData = this.states.getStates(data);
    this.cstates = countryData.states;
    this.countryCode = countryData.country_code;
  }

  signInasUser() {
    this.userService.signInasUser(this.memberData);
  }
  onMemberLevel(data) {
    this.memberData.member_level = data;
    this.userService.changeMemberLevel(this.memberData).subscribe(data1 => {
      this.toastr.success(`Member Level Changed to '${ data}'`, 'Success!');
      this.communicationService.sendMessage('done');
    }, err => {
      console.log(err.json());
    });
  }
 // assign filename
 handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // tslint:disable-next-line:max-line-length
    $('#dropZone').css({'background-image': 'url(' + window.URL.createObjectURL(files[0]) + ')' , 'background-position': 'center', 'background-size': 'cover' });

  }

    // drag and drop
    public dropped(event: UploadEvent) {
      this.files = event.files;
      for (const droppedFile of event.files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.fileToUpload = file;
            // tslint:disable-next-line:max-line-length
            $('#dropZone').css({'background-image': 'url(' + window.URL.createObjectURL(this.fileToUpload) + ')' , 'background-position': 'center', 'background-size': 'cover' });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }
    }

    onUpload() {
      this.userService.upLoadImage(this.fileToUpload).subscribe(data => {
        this.memberData.profileImage = data.data.url;
        this.fileToUpload = null;
        $('#dropZone').css('background-image', '');
        const message = 'Background image saved!';
        this.toastr.success(message, 'Success!');
        this.profileCloseBtn.nativeElement.click();
      }, error => {
        this.toastr.error(error, 'error');
        });
    }
    cancelPassword() {
      document.getElementById('letter').classList.remove('valid');
      document.getElementById('capital').classList.remove('valid');
      document.getElementById('number').classList.remove('valid');
      document.getElementById('special').classList.remove('valid');
      document.getElementById('length').classList.remove('valid');
      document.getElementById('letter').classList.add('invalid');
      document.getElementById('capital').classList.add('invalid');
      document.getElementById('number').classList.add('invalid');
      document.getElementById('special').classList.add('invalid');
      document.getElementById('length').classList.add('invalid');
    }

    clearImage() {
      this.fileToUpload = null;
      $('#dropZone').css('background-image', '');
      // $('.upload-table').css('display', 'none');
    }

    isFirstZero() {
      this.countryCode.toString();
      if (this.countryCode === '0') {
        this.countryCode = '';
        $('#code').val('');
      }
      if (this.countryCode.charAt(0) === '0') {
      this.countryCode = this.countryCode.slice(1);
      $('#code').val(this.countryCode);
      }
    }
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ( ((47 < charCode) && (charCode < 58)) || (charCode === 45)) {
        return true;
    }
    return false;
  }

  isNumberCode(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ( ((47 < charCode) && (charCode < 58))) {
        return true;
    }
    return false;
  }

  updateMember(form: NgForm) {
    this.memberData.country_code = form.value.country_code;
    this.userService.changeMemberLevel(this.memberData).subscribe(data => {
      this.toastr.success(`Member Data Updated.`, 'Success!');
      this.closeMainModal.nativeElement.click();
      this.communicationService.sendMessage('done');
    }, err => {
      console.log(err.json());
    });
  }
}
