import { Http, HttpModule } from '@angular/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';


@Injectable()
export class ColorsService{
 token: String;
 serverUrl = environment.serverUrl;
 dToken;
    constructor(
        private http: Http,
    private router: Router,
   
){}
    createTheme(data){
        // console.log('called');
        return this.http.post( this.serverUrl+'api/color-theme', data).map(response => response.json());

    };

    //get logged in report
    getThemes(){
        return this.http.get( this.serverUrl+'api/color-theme').map(response => response.json());

    };

    //get list of users
    updateTheme(themeId, data){
        return this.http.put( this.serverUrl+'api/color-theme/'+ themeId, data).map(response => response.json());
    };
    
    //change user level
    changeMemberLevel(data){
        // console.log(data);
        return this.http.put(this.serverUrl+'users/member-level', data).map(response => response.json().data);
    }
}