import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../authentiction/authentication.service';
import { UsersService } from '../core/users.service';
import { CommunicationService } from '../shared/communication';
import { Store } from '@ngrx/store';
import { MemberState } from '../shared/store/member-profile/m-profile.reducer';
import * as MemberActions from '../shared/store/member-profile/m-profile.actions';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users;
  search;
  myUser;
  myProfile;
  MLockdown = false;
  role = 'member';
  dataTable: any;
  teamMembers;
  isUsers = false;
  constructor(private userService: UsersService,
    private authService: AuthService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private store: Store<MemberState>,
    titleService: Title
  ) {
    titleService.setTitle('Member List');
  }

  ngOnInit() {
    this.myProfile = JSON.parse(localStorage.getItem('user'));
    this.fetchUsers();
    this.communicationService.getMessage().subscribe(
      message => {
        if (message) {
          this.fetchUsers();
        }
      });
  }

  fetchUsers() {
    this.userService.getUsers().subscribe((data: any) => {
      this.users = data.data;
      if (this.users) {
        this.gotUsers();
      }
    });
  }

  findUsers(tName) {
    this.userService.findUsers({ teamName: tName })
      .subscribe((data: any) => {
        this.teamMembers = data.data;
      }, err => {
        console.log(err);
      });
  }
  onSelectUser(data) {
    localStorage.setItem('member', JSON.stringify(data));
    this.myUser = data;
    this.MLockdown = this.myUser.lockdown;
    this.role = this.myUser.roles;
    // this.communicationService.sendMessage(data);
    this.store.dispatch(new MemberActions.GetMember(JSON.parse(localStorage.getItem('member'))));
    localStorage.removeItem('member');
  }
  gotUsers() {
    setTimeout(() => this.isUsers = true, 1000);
  }

  onEditProfile(from: NgForm) {
  }

  onSignout() {
    this.authService.signout();
  }

}
