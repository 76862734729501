import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../authentication.service';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.css']
})
export class VerifyCodeComponent implements OnInit {
mfa;
type;
email;
phone;
  constructor( private authService: AuthService) { }

  ngOnInit() {
    this.mfa = JSON.parse(localStorage.getItem('mfa'));
    this.type = localStorage.getItem('type');

  }

  onSendCode(form: NgForm) {
    this.authService.verifyMfaCode(form.value);
  }
  
  onCancel(){
    window.location.href = 'signin';
  }
  //code validation
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  } 
}
