import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  count;
  loggedInReport;
  loginData = [];
  backuplist = [];

  title = 'Bar Chart Example in Angular 4';

  // ADD CHART OPTIONS. 
  chartOptions = {
    responsive: true,
    legend:{
      position: 'bottom',
      fullWidth: true,
      labels: {
        fontSize: 20,
        fontStyle: 'bold',
        padding: 20,
      }
    },
    tooltips: {
      enabled: false
    },
    hover: {
      mode: null
    },
    pieceLabel: {
      render: 'value',
      fontSize: 30,
      fontStyle: 'bold',
      fontColor: '#fff',
      overlap: true,
    }
  }

    // ADD CHART OPTIONS. 
    loggedInChartOptions = {
      responsive: true,
      legend:{
        position: 'bottom',
        fullWidth: true,
        labels: {
          fontSize: 23,
          fontStyle: 'bold',
          padding: 20,
        }
      },
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      },
      pieceLabel: {
        render: 'value',
        fontSize: 30,
        fontStyle: 'bold',
        fontColor: '#fff',
        overlap: true,
      }
    }

  labels =  [
    'Last 24 Hours',
    'Last 7 Days',
    'Last 30 Days'
];

  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData = [
    {
      data: []
    }
  ];

  // CHART COLOR.
  colors = [
    { 
      backgroundColor: [
        'Green',
        'Blue',
        'Yellow',
    ]
    },
  ];
  labels2 =  [
    'Current Logged In',
    'Total'
];

  chartData2 = [
    {
      data: []
    }
  ];

  constructor(private userService: UsersService) { }

  ngOnInit() {
    this.userService.getCount().subscribe((data: any) => {
      this.count = data.data;
      this.loginData[1] = data.data.total;
    });

    this.userService.getReport().subscribe( (data: any) => {
      this.loggedInReport = data.data;
      if (data) {
        this.setData(this.loggedInReport);
      }
    });

    this.userService.backuplist().subscribe( (data: any) => {
      this.backuplist = data.data;
    });

  }

  setData(data) {
    const rdata = []
    rdata[0] = data.pastDay;
    rdata[1] = data.pastWeek;
    rdata[2] = data.pastThirty;
    this.loginData[0] = data.current;
    this.chartData = [
      {
        data: rdata
      }
    ];
    this.chartData2 = [
      {
        data: this.loginData
      }
    ];
  }

}
