import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../authentication.service';

@Component({
  selector: 'app-send-mfa-code',
  templateUrl: './send-mfa-code.component.html',
  styleUrls: ['./send-mfa-code.component.css']
})
export class SendMfaCodeComponent implements OnInit {
mfa;
email;
phone;
isDisabled = true;
  constructor(private authService: AuthService) { }

  ngOnInit() {
     this.mfa = JSON.parse(localStorage.getItem('mfa'));
  }
  
  //send mfa code
  onSend(form: NgForm){  
      localStorage.setItem('type', form.value.optradio);
          this.authService.sendMfaCode({type: form.value.optradio});
  }
}
