import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../authentiction/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lockdown',
  templateUrl: './lockdown.component.html',
  styleUrls: ['./lockdown.component.css']
})
export class LockdownComponent implements OnInit {

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('closeVerify') closeVerify: ElementRef;
  @ViewChild('closeCancel') closeCancel: ElementRef;


  constructor(
              private authService: AuthService,
              private toastr: ToastrService,
              titleService: Title,
                ) {
    titleService.setTitle('Lockdown');
  }

  ngOnInit() {
  }

  sendOtp() {
    this.authService.sendLockdwonOtp();
  }

  onSendCode(form: NgForm) {
    this.authService.verifyLockdownCode(form.value).subscribe(data => {
      console.log(data);
      this.toastr.success('application has been locked out', 'Success!');
     this.closeVerify.nativeElement.click();
      this.closeBtn.nativeElement.click();
    }, err => {
      this.toastr.error(err.json().error.message, 'Error!');
    });
  }

  lCancel(){
    this.authService.cancelLockdown().subscribe(data => {
      this.closeCancel.nativeElement.click();
      this.toastr.success('Lockdown Canceled. Normal functions resumed. Members can login now.', 'Success!');
    }, err => {
      this.toastr.error(err.json().error.message, 'Error!');
    });
  }

  //code validation
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
  }
}
