import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'jquery';
import { AuthService } from './authentiction/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  timer = 60;
  timeOut;
  timeInterval;
  currentRoute;
  constructor( private authService: AuthService,
               private router: Router) {

  }
  ngOnInit() {
    this.router.events.subscribe( () => {
        this.setInitial();
    });
}

setInitial() {
    this.currentRoute = window.location.pathname;
    // tslint:disable-next-line:max-line-length
    if ( this.currentRoute.includes('/M/') || this.currentRoute.includes('/members') || this.currentRoute.includes('/lockdown') || this.currentRoute.includes('/color-themes') || this.currentRoute.includes('/test-reports')) {        
        console.log(localStorage.getItem('timeOut'));
        
        const expiry = new Date(localStorage.getItem('timeOut')).getTime();
        const now = new Date();
        if (!localStorage.getItem('timeOut') || expiry < now.getTime()) {
            this.authService.signout();
        }
        this.setTimer();
    } else {
        this.clear();
    }
}
  clickReset() {
    this.currentRoute = window.location.pathname;
    // tslint:disable-next-line:max-line-length
    if ( this.currentRoute.includes('/M/') || this.currentRoute.includes('/members') || this.currentRoute.includes('/lockdown') || this.currentRoute.includes('/color-themes') || this.currentRoute.includes('/test-reports')) {
        const expiry = new Date();
        expiry.setMinutes(expiry.getMinutes() + 10);
        localStorage.setItem('timeOut', String(expiry));
        this.clear();
        this.setTimer();
    } else {
        this.clear();
    }
  }
    setTimer() {
        // const expiry = new Date(localStorage.getItem('timeOut')).getTime();
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
                this.timer = 60;
                $('#openTimeout').click();
                this.startTimer();
        }, 10 * 60 * 1000 );

    }

    startTimer() {
         this.timeInterval = setInterval( () => {
            if (this.timer === 0) {
                this.clear();
                this.authService.signout();
            } else {
                this.timer = this.timer - 1;
            }
        }, 1000);
    }
    clear() {
        clearTimeout(this.timeOut);
        clearInterval(this.timeInterval);
    }
}
