import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ColorsService } from './colors-theme.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-color-crud',
  templateUrl: './color-crud.component.html',
  styleUrls: ['./color-crud.component.css']
})
export class ColorCrudComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;
  themes;
  editTheme;
  name;
  x1;
  x2;
  x3;
  x4;
  x5;
  themeId;
  themeIndex;
  constructor( private colorsSerice: ColorsService,
               titleService: Title ) {
    titleService.setTitle('Color Theme');
   }

  ngOnInit() {
    this.colorsSerice.getThemes().subscribe(res => {
      this.themes = res.data;
    });
  }

  onSelectTheme(item) {
    this.editTheme = item;
    this.name = item.name;
    this.x1 = item.subcolor.x1;
    this.x2 = item.subcolor.x2;
    this.x3 = item.subcolor.x3;
    this.x4 = item.subcolor.x4;
    this.x5 = item.subcolor.x5;
    this.themeId = item.id;
    $('#x1').val(this.x1);
    $('#x2').val(this.x2);
    $('#x3').val(this.x3);
    $('#x4').val(this.x4);
    $('#x5').val(this.x5);
  }

  onCreate( form: NgForm) {
    if (this.themeId) {
      this.colorsSerice.updateTheme(this.themeId, { name: form.value.name, subcolor: {
        x1: form.value.x1 ? form.value.x1 : this.x1,
        x2: form.value.x2 ? form.value.x2 : this.x2,
        x3: form.value.x3 ? form.value.x3 : this.x3,
        x4: form.value.x4 ? form.value.x4 : this.x4,
        x5: form.value.x5 ? form.value.x5 : this.x5
      }}).subscribe(res => {
        this.themes[this.themeIndex] = res.data;
        this.closeModal.nativeElement.click();
      });
    }

    if (!this.themeId) {
      this.colorsSerice.createTheme({name: form.value.name, subcolor: {
        x1: form.value.x1 ? form.value.x1 : this.x1,
        x2: form.value.x2 ? form.value.x2 : this.x2,
        x3: form.value.x3 ? form.value.x3 : this.x3,
        x4: form.value.x4 ? form.value.x4 : this.x4,
        x5: form.value.x5 ? form.value.x5 : this.x5
      }}).subscribe(res => {
        this.themes.push(res.data);
        this.closeModal.nativeElement.click();

      });
    }
  }

  onChange() {
    const tx1: any = $('#x1');
    const tx2: any = $('#x2');
    const tx3: any = $('#x3');
    const tx4: any = $('#x4');
    const tx5: any = $('#x5');
    if (tx1.val() === null || tx1.val() === '') {
      $('#x1').val('#');
    }
    if (tx2.val() === null || tx2.val() === '') {
      $('#x2').val('#');
    }
    if (tx3.val() === null || tx3.val() === '') {
      $('#x3').val('#');
    }
    if (tx4.val() === null || tx4.val() === '') {
      $('#x4').val('#');
    }
    if (tx5.val() === null || tx5.val() === '') {
      $('#x5').val('#');
    }
    if (tx1.val().charAt(0) !== '#') {
      $('#x1').val('#' + tx1.val());
    }
    if (tx2.val().charAt(0) !== '#') {
      $('#x2').val('#' + tx2.val());
    }
    if (tx3.val().charAt(0) !== '#') {
      $('#x3').val('#' + tx3.val());
    }
    if (tx4.val().charAt(0) !== '#') {
      $('#x4').val('#' + tx4.val());
    }
    if (tx5.val().charAt(0) !== '#') {
      $('#x5').val('#' + tx5.val());
    }
  }

  checkHex(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ( ( (64 < charCode) && (charCode < 71) ) || ( (96 < charCode) && (charCode < 103) ) || ( (47 < charCode) && (charCode < 58) ) ) {
        return true;
    }
    return false;
  }

  x1Changed( data) {
    const tx1 = $('#x1').val();
    if ((<any>$('#x1')).val().length !== 4) {
      $('#x1').val(data);
    }
  }
  x2Changed( data) {
    if ((<any>$('#x2')).val().length !== 4) {
      $('#x2').val(data);
    }
  }
  x3Changed( data) {
    if ((<any>$('#x3')).val().length !== 4) {
      $('#x3').val(data);
    }
  }
  x4Changed( data) {
    if ((<any>$('#x4')).val().length !== 4) {
      $('#x4').val(data);
    }
  }
  x5Changed( data) {
    if ((<any>$('#x5')).val().length !== 4) {
      $('#x5').val(data);
    }
  }
}
