import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
// import {ColorPickerModule} from 'angular2-color-picker';

import {
    RECAPTCHA_SETTINGS,
    RecaptchaSettings,
    RecaptchaLoaderService,
    RecaptchaModule,
} from 'ng-recaptcha';

import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { SignupComponent } from './authentiction/signup/signup.component';
import { SigninComponent } from './authentiction/signin/signin.component';
import { ForgetpassComponent } from './authentiction/forgetpass/forgetpass.component';
import { ResetpasswordComponent } from './authentiction/resetpassword/resetpassword.component';
import { HomeComponent } from './core/home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AppRouting } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileDropModule } from 'ngx-file-drop';
import { AuthService } from './authentiction/authentication.service';
import { ChartsModule } from 'ng2-charts';
import 'chart.piecelabel.js';
import { UsersService } from './core/users.service';
import { UsersComponent } from './users/users.component';
import { VerifyCodeComponent } from './authentiction/verify-code/verify-code.component';
import { SendMfaCodeComponent } from './authentiction/send-mfa-code/send-mfa-code.component';
import { GeneratorService } from './shared/password-gerator.service';
import { NotifierService } from './notifier.service';
import { ColorCrudComponent } from './color-crud/color-crud.component';
import { ColorsService } from './color-crud/colors-theme.service';
import { LockdownComponent } from './lockdown/lockdown.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { CountryStateService } from './shared/country-state.service';
import { CommunicationService } from './shared/communication';
import { StoreModule } from '@ngrx/store';
import { memberReducer } from './shared/store/member-profile/m-profile.reducer';
import { HttpClientModule } from '@angular/common/http';
import { TestReportsComponent } from './test-reports/test-reports.component';
import { CreateTeamComponent } from './create-team/create-team.component';

const globalSettings: RecaptchaSettings = {siteKey: '6Le1RdUUAAAAACipLsIux0wScP3YdLVd7uHJQB32'};

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    UsersComponent,
    VerifyCodeComponent,
    SendMfaCodeComponent,
    ForgetpassComponent,
    ResetpasswordComponent,
    ColorCrudComponent,
    LockdownComponent,
    ProfileComponent,
    TestReportsComponent,
    CreateTeamComponent
  ],
  imports: [
    BrowserModule,
    PasswordStrengthBarModule,
    AppRouting,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot(),
    ChartsModule,
    Ng2SearchPipeModule,
    BootstrapModalModule,
    HttpClientModule,
    DataTablesModule,
    ColorPickerModule,
    FileDropModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({member: memberReducer}),
    ToastrModule.forRoot({ positionClass: 'toast-center-center' })
  ],
  exports: [
  ],
  providers: [AuthService, UsersService, GeneratorService,
    NotifierService, ColorsService,
    {
        provide: RECAPTCHA_SETTINGS,
        useValue: globalSettings,
    },
    CountryStateService, CommunicationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
