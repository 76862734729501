import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../authentiction/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user;
  constructor( private authService: AuthService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if(!this.user) {
      this.authService.signout();
    }
  }
  onSignout() {
    this.authService.signout();
  }

}
