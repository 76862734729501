import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { owaspPasswordStrengthTest } from 'owasp-password-strength-test';
import { AuthService } from '../authentication.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public account = {
    password: <string>null
  };
  recaptchaStr = '';
  iamrobot = true;
  public barLabel = 'Password strength:';
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  constructor(
              private authService: AuthService,
              private titleService: Title, ) { }

  ngOnInit() {
    this.titleService.setTitle('Sign up to FinishLine');
    window['getResponceCapcha'] = this.getResponceCapcha.bind(this);
  }

  onLoginClick(captchaRef: any): void {
    if (this.recaptchaStr) {
        captchaRef.reset();
    }
    captchaRef.execute();
}

public resolved(captchaResponse: string, form: NgForm): void {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
        this.onSignup(form);
    }
}

  getResponceCapcha(captchaResponse: string) {
    $('#buttonID').prop('disabled', false);
  }
  onSignup(form: NgForm ) {
    if (form.invalid) {
        return;
    }
    this.authService.signup(form.value);
  }
}
