import { MemberProfile } from '../../interface/m-profile.model';
import * as MemberActions from './m-profile.actions';

export interface MemberState {
    readonly member: MemberProfile;
}

export function memberReducer(state: MemberProfile, action: MemberActions.MemberActions) {

    switch (action.type) {
        case MemberActions.GET_MEMBER:
            return action.payload;

        default: return state;
    }
}
