import { NotifierRequest } from './shared/notifier.request';
import { Injectable } from '@angular/core';
import {
  ToastyService,
  ToastyConfig,
  ToastyComponent,
  ToastOptions,
  ToastData
} from 'ng2-toasty';
import { Subject, Observable, Subscription } from 'rxjs/Rx';

/**
 * Toasty Service
 * @export
 * @class NotifierService
 */
@Injectable()
export class NotifierService {
  default: NotifierRequest = {
    title: 'Default',
    msg: 'Default',
    showClose: true,
    timeout: 3000,
    theme: 'default',
    type: 'default'
  };

  /**
   * Creates an instance of NotifierService.
   *
   * @param {ToastyService} toastyService
   * @param {ToastyConfig} toastyConfig
   *
   * @memberOf NotifierService
   */
  constructor(private toastyService: ToastyService,
    private toastyConfig: ToastyConfig) { }

  /**
   * Notifies User and shows growl-style alerts and messages 
   * for application.
   *
   * @param {NotifierRequest} [params=this.default]
   *
   * @memberOf NotifierService
   */
  addToast(params: NotifierRequest = this.default) {
    const options = Object.assign(this.default, params);
    const toastOptions: ToastOptions = this.setOptions(options);

    switch (params.type) {
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
      default: this.toastyService.default(toastOptions); break;
    }
  }

  /**
   * Sets Options for Toasty
   *
   * @param {NotifierRequest} options
   * @returns {ToastOptions}
   *
   * @memberOf NotifierService
   */
  setOptions(options: NotifierRequest): ToastOptions {
    return {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: 3000,
      theme: options.theme,
      
    };
  }
}
