import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
 token: String;
 serverUrl = environment.serverUrl;
 dToken;
    constructor(
        private http: Http,
        private router: Router,
        private toastr: ToastrService,
        private http1: HttpClient
    ) {}
// get header data
    getHeader() {

        const aToken = localStorage.getItem('access_token');
        const httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${aToken}`
            })
        };
        return httpOptions;
    }

    signin(data) {
        this.http.post( this.serverUrl + 'login', data).subscribe(
            res => {
                    localStorage.setItem('mfa', JSON.stringify(res.json().data));
                    this.router.navigate(['send-mfa-code']);
        },
            err => {
                this.toastr.error(err.json().error.message, 'Error!');
            });
    }

    sendMfaCode(data) {
        const mfa = JSON.parse(localStorage.getItem('mfa'));
        data.id = mfa.id;
        this.http.post( this.serverUrl + 'send-mfa-otp', data).subscribe(
            data => {
                this.router.navigate(['verify-mfa-code']);
        },
       err => {
                this.toastr.error(err.json().error.message, 'Error!');
            });
    }

    verifyMfaCode(data) {
        const mfa = JSON.parse(localStorage.getItem('mfa'));
        data.id = mfa.id;
        this.http.post( this.serverUrl + 'verify-mfa-otp', data).subscribe(
            res => {
                localStorage.removeItem('mfa');
                this.token = res.json().data[1].token;
                localStorage.setItem('access_token', JSON.stringify(this.token));
                this.getUserData();
        },
       error => {
                this.toastr.error(error.json().error.message, 'Error!');
            });
    }

    getUserData() {
        this.http1.get(this.serverUrl + 'users/my-profile', this.getHeader()).subscribe((data: any) => {
            const slug = data.data.slug;
            const user = data.data;
            const expiry = new Date();
            expiry.setMinutes(expiry.getMinutes() + 10);
            localStorage.setItem('timeOut', String(expiry));
            localStorage.setItem('user', JSON.stringify(user));
                this.router.navigate(['M', slug ]);
        }, err => {
            this.toastr.error(err.data.message, 'Error!');
        });
    }

    signup(uData) {
        this.http.post(this.serverUrl + 'signup', uData).subscribe(data => {
            data.json();
            if (data.json().data.message !== 'please sign In to continue.') {
                this.toastr.error(data.json().data.message, 'Error!');
            }
            window.location.href = 'signin';
        });
    }

    forgetpassword(data) {
        return this.http.post(this.serverUrl + 'resetPassword', data).map(response => response.json());

    }

    checkTokenValidation(data) {
        this.http.get(this.serverUrl + 'check?token=' + data.token).subscribe(res => {
            console.log(res.json().data.message);
        }, error => {
            this.toastr.error(error.json().error.message, 'Error!');
            this.router.navigate(['reset-password']);
        });
    }

    resetpassword(data) {
        this.http.post(this.serverUrl + 'reset', data).subscribe( res => {
                if (res.json().data.message !== 'please sign In to continue.') {
                this.toastr.error(res.json().data.message, 'Success!');
            }
            window.location.href = 'signin';
        }, error => {
            this.toastr.error(error.json().error.message, 'Error!');

        });
    }

    sendLockdwonOtp() {
        return this.http1.get(this.serverUrl + 'send-lockdown-otp', this.getHeader()).subscribe(response => response);
    }

    verifyLockdownCode(data) {
        this.token = localStorage.getItem('access_token');
        return  this.http.post( this.serverUrl + 'verify-lockdown-otp?token=' + this.token, data).map(response => response.json());
    }

    cancelLockdown() {
        this.token = localStorage.getItem('access_token');
        return  this.http.get( this.serverUrl + 'cancel-lockdown?token=' + this.token).map(response => response.json());
    }

    signout() {
        console.log('signout called');
        localStorage.removeItem('timeOut');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        window.location.href = 'signin';
    }

    fetchReports() {
        return this.http.get( `${this.serverUrl}api/test-reports`).map(response => response.json());
    }
    teamCreation(data){
        console.log("In service",data);
        return this.http.post(`${this.serverUrl}team-creation`,data).map(response => response.json());
    }
}
