import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../authentication.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  iamrobot = true;
 creds;
 recaptchaStr = '';
  constructor( private authservice: AuthService,
    private titleService: Title,
  ) { }

  // site key invisible 6Le1RdUUAAAAACipLsIux0wScP3YdLVd7uHJQB32
  ngOnInit() {
    this.titleService.setTitle('Sign to FinishLine');
    window['getResponceCapcha'] = this.getResponceCapcha.bind(this);
}

onLoginClick(captchaRef: any): void {
    if (this.recaptchaStr) {
        captchaRef.reset();
    }
    captchaRef.execute();
}

public resolved(captchaResponse: string, form: NgForm): void {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
        this.onSignin(form);
    }
}

getResponceCapcha(captchaResponse: string) {
    // console.log('I am called');
    $('#buttonID').prop('disabled', false);
}
// signin data
onSignin(form: NgForm) {
    if (form.invalid) {
        return;
    }
    this.authservice.signin(form.value);
  }
}
