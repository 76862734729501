import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';

import { AuthService } from '../authentication.service';


@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.css']
})
export class ForgetpassComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router,
              private titleService: Title
) { }

  ngOnInit() {
    this.titleService.setTitle('Reset Your FinishLine Password');
  }

  //request for reset password link
  onResetPassword(form : NgForm){
    this.authService.forgetpassword({email: form.value.email}).subscribe(
      response => {
        alert(response.data.message);
        this.router.navigate(['signin']);
      }
    );
  }
}
