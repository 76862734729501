import { Action } from '@ngrx/store';
import { MemberProfile } from '../../interface/m-profile.model';

export const GET_MEMBER = 'GET_MEMBER';

export class GetMember implements Action {
    readonly type = GET_MEMBER;

    constructor(public payload: MemberProfile) { }
}

export type MemberActions = GetMember;
