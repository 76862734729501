import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentiction/authentication.service';

@Component({
  selector: 'app-test-reports',
  templateUrl: './test-reports.component.html',
  styleUrls: ['./test-reports.component.css']
})
export class TestReportsComponent implements OnInit {
  reports = [];
  constructor( private authentication: AuthService) { }

  ngOnInit() {
    this.authentication.fetchReports()
    .subscribe((report: any) => {
      // console.log(report);
      this.reports = report.data;
    }, err => {
      console.log(err);
    })
  }

}
