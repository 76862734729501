import { Component, OnInit } from '@angular/core';
import { AuthService } from '../authentiction/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})
export class CreateTeamComponent implements OnInit {
teamName;
email;
password;
cPassword;
firstName;
lastName;
  constructor(private authservice: AuthService, private toastr:ToastrService, private router:Router) { }

  ngOnInit() {
  }
  createTeam(){
    console.log("button click");
    
    this.authservice.teamCreation({
      teamName:this.teamName,
      email:this.email,
      password:this.password,
      cPassword:this.cPassword,
      lastName:this.lastName,
      firstName:this.firstName
    }).subscribe(
      (data) => {
          console.log(data);   
          this.router.navigate(['members']);
      },
      (err) => {
        this.toastr.error(err.json().error.message, 'Error!');
      }
  );
  }
}
