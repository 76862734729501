import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { AuthService } from '../authentication.service';
import { GeneratorService } from '../../shared/password-gerator.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  isRandom = false;
  newPassword;
  verifyPassword;
  constructor( private activatedRoute : ActivatedRoute,
  private authservice: AuthService,
  private titleService: Title,
  private gereratorService: GeneratorService) { }

  ngOnInit() {
    this.titleService.setTitle('Reset Your FinishLine Password');
    this.activatedRoute.params.subscribe((params: Params) =>{
      var token = params['token'];
      this.authservice.checkTokenValidation({token: token})
    });
  }

  //set new password
  onResetPassword(form: NgForm){
      var password = form.value.changePWD;
      this.activatedRoute.params.subscribe((params: Params) =>{
        var token = params['token'];
        this.authservice.resetpassword({token: token, password: password})
      });
    document.getElementById("letter").classList.remove("valid");
    document.getElementById("capital").classList.remove("valid");
    document.getElementById("number").classList.remove("valid");
    document.getElementById("special").classList.remove("valid");
    document.getElementById("length").classList.remove("valid");
    document.getElementById("letter").classList.add("invalid");
    document.getElementById("capital").classList.add("invalid");
    document.getElementById("number").classList.add("invalid");
    document.getElementById("special").classList.add("invalid");
    document.getElementById("length").classList.add("invalid");
  }

  //check password validations
  checkPassword(password){
    this.isRandom = false;
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var special = document.getElementById("special");
    var length = document.getElementById("length");
    var lowerCaseLetters = /[a-z]/g;
    if(password.match(lowerCaseLetters)) {  
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if(password.match(upperCaseLetters)) {  
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if(password.match(numbers)) {  
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }
    
    // Validate special if(/^[a-zA-Z0-9- ,_]*$/.test(str) == false){
    var specials = /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g;
    if(password.match(specials)) {  
      special.classList.remove("invalid");
      special.classList.add("valid");
    } else {
      special.classList.remove("valid");
      special.classList.add("invalid");
    }

    if(password.length >= 10) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }
  }

  cancelPassword(){
    document.getElementById("letter").classList.remove("valid");
    document.getElementById("capital").classList.remove("valid");
    document.getElementById("number").classList.remove("valid");
    document.getElementById("special").classList.remove("valid");
    document.getElementById("length").classList.remove("valid");
    document.getElementById("letter").classList.add("invalid");
    document.getElementById("capital").classList.add("invalid");
    document.getElementById("number").classList.add("invalid");
    document.getElementById("special").classList.add("invalid");
    document.getElementById("length").classList.add("invalid");
  }

  //deny space
  isSpace(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === 32) {
        return false;
    }
    return true;
  } 
  
  //generate new password
  generatePassword(){
    this.newPassword = this.gereratorService.generate(10,true,true,true);
    this.checkPassword(this.newPassword);
  }
}
